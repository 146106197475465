// nuxt-ui/Shared/composables/season/matches.ts

import type { TDateISO } from "~/src/Shared/DateTypes";
import type { DateTimeFormatOptions } from "@intlify/core-base";
import type { UseFetchParams } from "~/src/Shared/Types";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";

interface UseMatches {
  useFetchParamsMatchData: (matchId: number, live: boolean) => UseFetchParams;
  formatMatchTime: (
    locale: string,
    matchDate: TDateISO,
    regionParam: string,
    regionTimeZone: string
  ) => string;
}

export const useMatches = (): UseMatches => {
  return {
    useFetchParamsMatchData,
    formatMatchTime,
  };

  function useFetchParamsMatchData(matchId: number, live: boolean): UseFetchParams {
    const { region } = useCompetitionStore();

    const path = `/api/v1/competition/matches/${matchId}`;
    const opts = {
      query: { live, regionApiId: region?.apiId },
    };

    return { path, opts };
  }

  function formatMatchTime(
    locale: string,
    matchDate: TDateISO,
    regionParam: string,
    regionTimeZone: string
  ): string {
    const appendixDate = ref<string>();

    if (regionParam === "espana") {
      appendixDate.value = locale === "es" ? "h ESP" : " ESP";
    } else if (regionParam === "americas" || regionParam === "world-cup") {
      appendixDate.value = locale === "es" ? "h CDMX" : " CDMX";
    }

    const dateOptions: DateTimeFormatOptions = {
      timeStyle: "short",
      timeZone: regionTimeZone,
    };

    return new Date(matchDate).toLocaleString(locale, dateOptions) + appendixDate.value;
  }
};
